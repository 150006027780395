<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the following reaction:</p>
      <p class="mb-5">
        <v-img :src="imageHeader" style="max-width: 550px" />
      </p>

      <p class="mb-2">
        Identify which product (A or B) is the Hofmann product and which is the Zaitsev product, and
        explain which is the major product.
      </p>
      <s-textarea v-model="inputs.input1" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemLC241PS9Q8',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    imageHeader() {
      return '/img/assignments/LC241/q8-header.png';
    },
  },
};
</script>
